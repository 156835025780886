.main-container {
    display: flex;
    justify-content: center;
}
.calc-container {
    margin-top: 3%;
    background-color: rgb(32, 32, 37);
    width: 55vh;
    height: 75vh;
    border-radius: 7px;
}
.result-container {
    height: 25%;
    width: 100%;
    background-color: rgb(32, 32, 37);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: flex;
    justify-content: right;
    align-items: center;
}
.key-container {
    height: 75%;
    width: 100%;
    background-color: rgb(56, 44, 77);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.result {
    color: white;
    font-size: 40px;
    text-align: right;
    margin-right: 5%;
}
.row1, .row2, .row3, .row4, .row5 {
    height: 20%;
    width: 100%;
    display: flex;
    padding-left: 1.5%;
}
.row1 {
    padding-top: 6%;
}
.row2 {
    margin-top: -1.5%;
}
.row3 {
    margin-top: -1.5%;
}
.row4 {
    margin-top: -1.5%;
}
.row5 {
    margin-top: -1.5%;
}
.bigBtn {
    height: 70%;
    width: 43.5%;
    margin-left: 3.5%;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
}
.smallBtn {
    height: 70%;
    width: 20%;
    margin-left: 3.5%;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
}
.numBgcolor {
    background-color: black;
    border-color: black;
    border: none;
    color: white;
}
.opBgcolor {
    background-color: rgb(57, 189, 255);
    border: none;
    color: white;
}